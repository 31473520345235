import il8n from '@/assets/language/index.js';
const { t } = il8n.global;

let validator = (rule,value,callback)=>{
    if((!Number.isInteger(Number(value)))){
        callback(new Error(t('otherWallatNft.relus1')))
    }else if(Number(value) < 1){
        callback(new Error(t('otherWallatNft.relus2')))
    }else{
        callback()
    }
}
const mainRules = {
    tenantId: [{ required: true, message: t('inputTit.xuanze'), trigger: 'blur' }],
    appId: [{ required: true, message:t('inputTit.xuanze'), trigger: 'blur' }],
    allCoin: [{ required: true, message: t('inputTit.xuanze'), trigger: 'blur' }],
    collectionName: [{ required: true, message: t('inputTit.shuru'), trigger: 'blur' }],
    collectionSymbol: [{ required: true, message: t('inputTit.shuru'), trigger: 'blur' }],
    description: [{ required: true, message: t('inputTit.shuru'), trigger: 'blur' }],
    collectionImg: [{ required: true, message: t('inputTit.shangchuan'), trigger: 'blur' }],
    creator: [{ required: true, message: t('inputTit.shuru'), trigger: 'blur' }],
    storageType: [{ required: true, message: t('inputTit.xuanze'), trigger: 'blur' }],
    mintType: [{ required: true, message: t('inputTit.xuanze'), trigger: 'blur' }],
    googleCode: [{ required: true, message: t('inputTit.shuru'), trigger: 'blur' }],
}
const mainRules1 = {
    tenantId: [{ required: true, message: t('inputTit.xuanze'), trigger: 'blur' }],
    appId: [{ required: true, message:t('inputTit.xuanze'), trigger: 'blur' }],
    allCoin: [{ required: true, message: t('inputTit.xuanze'), trigger: 'blur' }],
    collectionName: [{ required: true, message: t('inputTit.shuru'), trigger: 'blur' }],
    collectionSymbol: [{ required: true, message: t('inputTit.shuru'), trigger: 'blur' }],
    description: [{ required: true, message: t('inputTit.shuru'), trigger: 'blur' }],
    collectionImg: [{ required: true, message: t('inputTit.shangchuan'), trigger: 'blur' }],
    maxItemQty: [{ required: true, message: t('inputTit.shuru'), trigger: 'blur' },
        {validator:validator, trigger: 'blur',}
    ],
    creator: [{ required: true, message: t('inputTit.shuru'), trigger: 'blur' }],
    storageType: [{ required: true, message: t('inputTit.xuanze'), trigger: 'blur' }],
    mintType: [{ required: true, message: t('inputTit.xuanze'), trigger: 'blur' }],
    googleCode: [{ required: true, message: t('inputTit.shuru'), trigger: 'blur' }],
}
export default{
    mainRules,mainRules1
}