<template>
    <div class="init">
        <el-form :model="fromValue" label-width="auto" ref='form_' class="fromClass"
            :rules='rules' label-position='top'>
            <!-- <el-form-item label="来源" prop="createFrom">
                <el-radio-group v-model="fromValue.createFrom" size="large" @change="changeProtocolType">
                    <el-radio-button :label="1">内部</el-radio-button>
                    <el-radio-button :label="2">外部</el-radio-button>
                </el-radio-group>
            </el-form-item> -->
            <el-form-item :label="$t('currencySetNft.name1')" prop="userId">
                <el-select v-model="fromValue.tenantId" :placeholder="$t('currencySetNft.name1')" @change="getItem('tenantId')" :teleported='false'>
                    <el-option :label="item.name" :value="item.id" v-for='item in tenantArray' :key = 'item.id'/>
                </el-select>
            </el-form-item>
            <el-form-item :label="$t('currencySetNft.name2')" prop="appId">
                <el-select v-model="fromValue.appId" :placeholder="$t('currencySetNft.name2')" @change="getItem('appId')" :teleported='false'>
                    <el-option :label="item.appName" :value="item.appId" v-for='item in appArray' :key = 'item.appId'/>
                </el-select>
            </el-form-item>
            <el-form-item :label="$t('otherWallatNft.name5')" prop="allCoin">
                <el-input v-model="fromValue.allCoin"  :placeholder="$t('inputTit.xuanze')" disabled>
                    <template #append v-if='fromValue.tenantId && fromValue.appId'>
                        <el-button text type="primary" @click="chooseCoin1(1)">{{$t('button.checks')}}</el-button>
                    </template>
                </el-input>
            </el-form-item>
             <el-form-item :label="$t('nfts.name20')" prop="collectionName">
                <el-input v-model="fromValue.collectionName" :placeholder="$t('inputTit.shuru')"/>
            </el-form-item>
             <el-form-item :label="$t('nfts.name21')" prop="collectionSymbol">
                <el-input v-model="fromValue.collectionSymbol" :placeholder="$t('inputTit.shuru')"/>
            </el-form-item>
             <el-form-item :label="$t('nfts.name2')" prop="description">
                <el-input v-model="fromValue.description" :placeholder="$t('inputTit.shuru')" type='textarea'/>
            </el-form-item>
             <el-form-item :label="$t('nfts.name15')" prop="collectionImg">
                <el-upload class="avatar-uploader"
                    :show-file-list="false" :auto-upload='false'
                    :on-change="beforeAvatarUpload">
                    <img v-if="fromValue.collectionImg" :src="fromValue.collectionImg" class="avatar" />
                    <el-icon v-else class="avatar-uploader-icon"><Plus /></el-icon>
                </el-upload>
                        <div class="lineWrite">
                            {{$t('nfts.relus1')}}1400px * 350px<br />
                            {{$t('nfts.relus2')}}<br />
                            {{$t('nfts.relus3')}}PNG、JPG、JPEG<br />
                        </div>
                <!-- <el-input v-model="fromValue.collectionImg" :placeholder="$t('inputTit.shuru')"/> -->
            </el-form-item>
             <el-form-item :label="$t('nfts.name16')" prop="maxItemQty" v-if='!supplyType'>
                <el-input v-model="fromValue.maxItemQty" :placeholder="$t('inputTit.shuru')" type="number" @change="getZero" />
            </el-form-item>
             <!-- <el-form-item label="mint权限" prop="mintType">
                <el-radio-group v-model="fromValue.mintType" size="large">
                    <el-radio-button :label="1">owner</el-radio-button>
                    <el-radio-button :label="2">允许任何人</el-radio-button>
                </el-radio-group>
            </el-form-item> -->
             <el-form-item label="Creator" prop="creator">
                <el-input v-model="fromValue.creator" disabled  :placeholder="$t('inputTit.xuanze')">
                    <template #append v-if='fromValue.allCoin'>
                        <el-button text type="primary" @click="chooseCoin1(2)">{{$t('button.checks')}}</el-button>
                    </template>
                </el-input>
                <!-- <el-select v-model="fromValue.creator" :placeholder="$t('inputTit.shuru')" :disabled='fromValue.allCoin ?false:true'  :teleported='false'>
                    <el-option :label="item.walletName" :value="item.walletAddress" v-for='item in creatorArray' :key = 'item.id'/>
                </el-select> -->
            </el-form-item>
             <el-form-item label="存储方式" prop="storageType">
                <el-radio-group v-model="fromValue.storageType" size="large">
                    <el-radio-button :label="1">IPFS</el-radio-button>
                    <el-radio-button :label="2">中心化</el-radio-button>
                </el-radio-group>
            </el-form-item>
             <el-form-item :label="$t('currencySetNft.name29')" prop="googleCode">
                <el-input v-model="fromValue.googleCode" :placeholder="$t('inputTit.shuru')"/>
            </el-form-item>
            
        </el-form>
        <el-dialog v-model="innerVisible1" width="800" :title="arrayText[style_]" append-to-body >
            <protocolView v-if='style_ === 1' @getCoin='getCoin1' ref='protocolView_'/>
            <addrView v-else @getCoin='getCoin1' ref='addrView_' />
        </el-dialog>
    </div>
</template>
<script setup>
    import { ref,reactive,defineEmits,getCurrentInstance,defineExpose,nextTick } from 'vue'
    import { userStore } from '@/store/user'
    import protocolView from './coin.vue'
    import Api_ from '@/api/index'
    import { ElNotification } from 'element-plus'
    import addRules from './addRules'
    import apiList from '@/const/apiList'
    import { Plus } from '@element-plus/icons-vue'
    const { $t } = getCurrentInstance().proxy;
    import { randomCoding } from '@/utils/util.js'
    import addrView from './addr.vue'
    
    const fromValue = reactive({ 
        userId: '',tenantId:'',appId:'',createFrom:1,
        allCoin:'',chain:'',protocol:'',collectionTemplateId:'',
        collectionName:'',description:'',collectionImg:'',
        maxItemQty:'',creator:'',googleCode:'',collectionSymbol: '',
        mintType: 1,storageType: '1'
    })

    //初始化是平台还是商家
    const isPlatform = ref(null);isPlatform.value = userStore().getIsPlatform
    fromValue.tenantId = userStore().getUserInfo[0].tenantId
    const style_ = ref(null);
    
    //初始化商家和app数组
    const arrayText = ['',$t('otherWallatNft.name5'),'Creator']
    const tenantArray = ref([])
    const appArray = ref([])
    apiList.getTenantList((data)=>{ tenantArray.value = data })
    apiList.getAppList((data)=>{appArray.value = data;},fromValue.tenantId)
    //链，协议；地址参数
    const innerVisible1 = ref(false)
    const addrView_ = ref(null)
    // fromValue
    const emit = defineEmits(['successFun'])
    const form_ = ref(null)
    const rules = ref(null);rules.value = addRules.mainRules1
    const protocolView_ = ref(null)
    const supplyType = ref(false)
    
    const getZero = (e)=>{
        if(parseInt(e) < 0){
            fromValue.maxItemQty = 0
        }
    }
    const chooseCoin1 = (index)=>{
        innerVisible1.value = true
        style_.value = index
        nextTick(()=>{
            if(index === 1){
                protocolView_.value.init_({
                    tenantId: fromValue.tenantId,
                    appId: fromValue.appId,
                })
            }else{
                addrView_.value.init_({
                    tenantId:fromValue.tenantId,
                    chain:fromValue.chain,
                    protocol:fromValue.protocol,
                    walletType:4,
                    appId: fromValue.appId,
                })
            }
        })
    }
    const completeFun = ()=>{//点击完成
        if (!form_.value) return
        form_.value.validate((e) => {
            if(e){
                fromValue.userId = fromValue.tenantId
                let obj_ = JSON.parse(JSON.stringify(fromValue))
                obj_.transactionId = randomCoding(64)
                delete obj_.allCoin
                delete obj_.googleCode
                Api_.addNftcollectioninfo(obj_,fromValue.googleCode).then(res=>{
                    if(res.code === 0){
                        emit('successFun')
                        ElNotification({
                            message: $t('alert.succAdd'),
                            type: 'success',
                        })
                    }else{
                        ElNotification({
                            message: res.msg,
                            type: 'error',
                        })
                    }
                })
            }
        })
    }
    const beforeAvatarUpload = (e)=>{
        let file = new FormData()
        file.append("file",e.raw)
        Api_.getImgUrl(file).then(res=>{
            if(res.code === 0){
                fromValue.collectionImg = res.data.url
            }
        })
    }
    const getCoin1 = (obj)=>{
        innerVisible1.value = false
        if(style_.value === 1){
            fromValue.allCoin = obj.chain + '/' + obj.protocol + '/' + obj.contractName
            fromValue.chain = obj.chain
            fromValue.protocol = obj.protocol 
            fromValue.collectionTemplateId = obj.templateId
            fromValue.mintType = obj.mintType
            if(obj.supplyType === 2){
                fromValue.maxItemQty = ''
                supplyType.value = true
                rules.value = addRules.mainRules1
            }else{
                supplyType.value = false
                rules.value = addRules.mainRules
            }
        }else if(style_.value === 2){
            fromValue.creator = obj.walletAddress
        }
    }
    const getItem = (str)=>{//选项框的联动设置
        if(str === 'tenantId'){
            fromValue.appId = ''
            apiList.getAppList((data)=>{appArray.value = data;},fromValue.tenantId)
        }
        fromValue.allCoin = ''
        fromValue.creator = ''
    }
    const setData1 = (obj)=>{
        if(obj){
            for(let index in fromValue){
                if(obj[index]){
                    fromValue[index] = obj[index]
                }
            }
            fromValue.allCoin = obj.chain + '/' + obj.protocol + '/' + obj.contractClassName
        }else{
            for(let index in fromValue){
                fromValue[index] = ''
            }
            fromValue.createFrom = 1
            fromValue.mintType = 1
            fromValue.storageType = '1'
        }
    }
    defineExpose({setData1,completeFun})
</script>
<style lang="scss" scoped>
    :deep(.avue-form__group .el-col) {
        display: flex;
    }
    .avatar-uploader .avatar {
        width: 178px;
        height: 178px;
        display: block;
    }
    .el-icon.avatar-uploader-icon {
        font-size: 28px;
        color: #8c939d;
        width: 178px;
        height: 178px;
        text-align: center;
    }
    :deep(.avatar-uploader .el-upload) {
        border: 1px dashed #ccc;
        border-radius: 6px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
        transition: var(--el-transition-duration-fast);
    }
    .lineWrite{
        line-height: 20px;
        font-size: 12px;
        margin-left: 16px;
        height: 178px;
        display: flex;
        align-items: flex-end;
    }
</style>